import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import ScrollToTop from "./components/scrollToTop";
import Header from "./components/Header";
import Footer from './components/Footer';
import FooterForm from "./components/footer_form";
import Metrics from "./components/metrics";

import './App.css';

import Home from "./pages/home";
import About from "./pages/about";
import Seo from "./pages/seo";
import Advertising from "./pages/advertising";
import Support from "./pages/support";

class App extends Component {
    render() {
        return (
            <BrowserRouter >
                <ScrollToTop>
                    <Header/>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/about" component={About}/>
                        <Route path="/seo" component={Seo}/>
                        <Route path="/advertising" component={Advertising}/>
                        <Route path="/support" component={Support}/>
                        <Route path="*" render={({staticContext}) => {
                            if (staticContext) {
                                staticContext.statusCode = 404
                            }
                            // return <Error />
                        }}/>
                    </Switch>
                    <FooterForm/>
                    <Footer/>
                    <Metrics/>
                </ScrollToTop>
            </BrowserRouter>
        );
    }
}

export default App;
