import React from 'react';
import {Helmet} from "react-helmet";
import ServiceItem from "../components/service_item";
import ServiceVideo from "../components/service_video";
import {animate} from "../components/Header";

const serviceItems = [
    {
        'img': 'mouse',
        'title': 'Размещение информации на сайте',
        'description': 'Добавляем тексты, фотографии и видео по заявкам клиентов. '
    },
    {
        'img': 'photo',
        'title': 'Обработка текста, фото и видео материалов',
        'description': 'Обрабатываем информацию для публикации на сайте.'
    },
    {
        'img': 'click',
        'title': 'Создание новых блоков, страниц, разделов',
        'description': 'Создаем / редактируем новые блоки, страницы, разделы.'
    },
    {
        'img': 'frame',
        'title': 'Написание модулей, форм, скриптов ',
        'description': 'Пишем php, js и css код любой сложности под задачи клиента.'
    },
    {
        'img': 'design',
        'title': 'Подготовка дизайн макетов, эскизов страниц',
        'description': 'Рисуем дизайн для новых разделов, страниц и блоков на сайте.'
    },
    {
        'img': 'consult',
        'title': 'Консультирование по техническим вопросам',
        'description': 'Отвечаем на вопросы, даем аналитику для принятия решения.'
    },
]

class Support extends React.Component {
    state = {
        lastScrollTop: 0,
        animatedShowServices: false,
    }

    isElementInViewport = (el) => {
        let rect = el.getBoundingClientRect();
        return (
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.bottom >= 0
        );
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].classList.add('services');
        document.getElementsByTagName('body')[0].classList.remove('index');
        window.addEventListener('scroll', this.onScroll);

        if (
            this.isElementInViewport(document.querySelector('.service__top')) &&
            document.querySelector('.service__top-title').classList.contains('initial')
        ) {
            document.querySelector('.service__top-title').classList.remove('initial');
            document.querySelector('.service__top-description').classList.remove('initial');
            setTimeout(() => document.querySelector('.service__top-button').classList.remove('initial'), 600);
        }
    }

    animateShowServices = () => {
        const serviceItems = document.getElementsByClassName('service__item');
        for (let i = 0; i < serviceItems.length; i++) {
            setTimeout(function () {
                serviceItems[i].classList.remove('initial')
            }, 200 * i);
        }
    }

    onScroll = (e) => {
        let scrollFromTop = e.target.documentElement.scrollTop + e.target.documentElement.clientHeight;
        let scrollFromTopToMiddle = e.target.documentElement.scrollTop + e.target.documentElement.clientHeight / 2;

        const supportPageOffset = window.pageYOffset !== undefined;
        const isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        const yPage = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

        if (document.getElementsByClassName('service__pluses-items').length > 0) {
            let rect = document.getElementsByClassName('service__pluses-items')[0].getBoundingClientRect();
            if (scrollFromTopToMiddle >= (rect.top + yPage) && this.state.lastScrollTop < e.target.documentElement.scrollTop) {
                let completedPixels = scrollFromTopToMiddle - (rect.top + yPage);
                let currentPixels = document.getElementsByClassName('service__pluses-progress__step')[0].clientHeight;
                if (currentPixels < completedPixels && completedPixels <= document.getElementsByClassName('service__pluses-progress')[0].clientHeight) {
                    document.getElementsByClassName('service__pluses-progress__step')[0].style.height = completedPixels + 'px';
                }

                const plusItems = document.getElementsByClassName('service__pluses-item');
                for (let i = 0; i < plusItems.length; i++) {
                    if (!plusItems[i].classList.contains('showed')) {
                        let rectItem = plusItems[i].getBoundingClientRect();
                        if (scrollFromTopToMiddle >= (rectItem.top + rectItem.height / 2 + yPage)) plusItems[i].classList.add('showed');
                    }
                }
            }
        }
        if (document.getElementsByClassName('service__info-items').length > 0) {
            let rectServices = document.getElementsByClassName('service__info-items')[0].getBoundingClientRect();
            if (scrollFromTop - 250 >= (rectServices.top + yPage) && !this.state.animatedShowServices) {
                this.setState({
                    animatedShowServices: true
                });
                this.animateShowServices();
            }

        }

        this.setState({
            lastScrollTop: e.target.documentElement.scrollTop
        })

        if (document.querySelector('.service__top') !== null) {
            if (
                this.isElementInViewport(document.querySelector('.service__top')) &&
                document.querySelector('.service__top-title').classList.contains('initial')
            ) {
                document.querySelector('.service__top-title').classList.remove('initial');
                document.querySelector('.service__top-description').classList.remove('initial');
                setTimeout(() => document.querySelector('.service__top-button').classList.remove('initial'), 600);
            }
        }
    }

    scrollToServices = () => {
        let target = document.getElementsByClassName("service__info-title")[0];
        animate(document.scrollingElement || document.documentElement, "scrollTop", "", document.documentElement.scrollTop, target.offsetTop - 50, 500, true);
    }

    scrollToForm = (e) => {
        document.getElementById('page-footer-service').value = e.currentTarget.getAttribute('data-service');
        document.getElementById('page-footer-tariff').value = e.currentTarget.getAttribute('data-tariff');
        let target = document.getElementsByClassName("footer-form")[0];
        animate(document.scrollingElement || document.documentElement, "scrollTop", "", document.documentElement.scrollTop, target.offsetTop - 50, 500, true);
    }

    render() {
        return (
            <section className="service__page support-page">
                <Helmet>
                    <title>Поддержка сайтов автомобильной тематики | Интернет-лаборатория Пластилин AutoTeam</title>
                    <meta name="description" content="Закажите поддержку сайта автомобильной тематики на 1C Битрикс, React ✔ Согласование контента ✔ Проектирование и дизайн сайта ✔ Front-end / back-end ★ Интернет-лаборатория Пластилин AutoTeam" />
                </Helmet>
                <div className="wrapper">
                    <div className="service__top">
                        <div className="service__top-content">
                            <h1 className="service__top-title h2 initial">Поддержка сайта</h1>
                            <div className="service__top-description text initial">Поддерживаем сайты наших клиентов, помогаем создавать цифровой контент. </div>
                            <button className="service__top-button arrow-button initial" onClick={this.scrollToServices}><span>Подробнее</span></button>
                        </div>
                        <div className="service__top-banner">
                            <ServiceVideo type="support"/>
                        </div>
                    </div>
                    <div className="service__info">
                        <h2 className="service__info-title h2">Услуга включает</h2>
                        <div className="service__info-description text">
                            Техническую поддержку дилерских платформ и сайтов автомобильной тематики.
                        </div>
                        <div className="service__info-items">
                            {
                                serviceItems.map((service, i) => {
                                    return (<ServiceItem service={service} key={`service_` + i}/>);
                                })
                            }
                        </div>
                    </div>
                    <div className="service__pluses">
                        <h2 className="service__pluses-title h2">Преимущества работы с нами</h2>
                        <div className="service__pluses-list">
                            <div className="service__pluses-items">
                                <div className="service__pluses-item">
                                    <span>Опыт поддержки сайтов  автодилеров более 5 лет</span>
                                    <div className="service__pluses-item__dot"/>
                                </div>
                                <div className="service__pluses-item">
                                    <div className="service__pluses-item__dot"/>
                                    <span>Оперативная реакция на обращения</span>
                                </div>
                                <div className="service__pluses-item">
                                    <span>Работа по заявкам клиента без абонентской платы</span>
                                    <div className="service__pluses-item__dot"/>
                                </div>
                                <div className="service__pluses-item">
                                    <div className="service__pluses-item__dot"/>
                                    <span>Умение работать с сайтами дилерских платформ</span>
                                </div>
                                <div className="service__pluses-item">
                                    <span>Составление отчетов для импортера, работа с ЭДО</span>
                                    <div className="service__pluses-item__dot"/>
                                </div>
                            </div>
                            <div className="service__pluses-progress"><span className="service__pluses-progress__step"/></div>
                        </div>
                    </div>
                </div>
                <div className="service__prices">
                    <h2 className="service__prices-title h2">Стоимость услуг</h2>
                    <div className="service__prices-text text">
                        Мы предлагаем единый тариф для удобства работы с нами в зависимости от сложности поставленных задач.
                    </div>
                    <div className="service__prices-list one-price-item">
                        <div className="service__price">
                            <div className="service__price-hour"><span className="service__price-hour__time">1 000 </span><span className="service__price-hour__rouble">₽</span>/час</div>
                            <div className="service__price-name">Тариф “Единый”</div>
                            <div className="service__price-description text">
                                В рамках технической поддержки мы выполняем задачи по заявкам клиентов, а в конце отчетного периода готовим закрывающие документы. Оплата производится по факту выполнения работ в конце месяца.
                            </div>
                            <ul className="service__price-items">
                                <li>Отсутствие фиксированной абонентской платы </li>
                                <li>Оперативное реагирование и оценка поставленных задач</li>
                                <li>Консультирование по техническим вопросам </li>
                            </ul>
                            <button className="service__price-button" onClick={this.scrollToForm} data-tariff="Единый" data-service="Поддержка">Начать работу</button>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Support;