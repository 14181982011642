import React from 'react';
import {Helmet} from "react-helmet";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import VideoPlayer from "../components/video_player";
import {NavLink} from "react-router-dom";
import { gsap } from 'gsap';

class Home extends React.Component {
    state = {
        videoUrl: '',
        videoPoster: '',
    }

    sliderSettings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    variableWidth: false,
                    centerMode: false
                }
            },
        ]
    };

    isElementInViewport = (el) => {
        let rect = el.getBoundingClientRect();
        return (
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.bottom >= 0
        );
    }

    onScroll = (e) => {
        let servicesBanners = document.getElementsByClassName('main__page-service');
        let scrollFromTopToMiddle = e.target.documentElement.scrollTop + e.target.documentElement.clientHeight / 2;

        const supportPageOffset = window.pageYOffset !== undefined;
        const isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        const yPage = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

        for (let i = 0; i < servicesBanners.length; i++) {
            let wrapperService = servicesBanners[i], rectItem = servicesBanners[i].getBoundingClientRect();
            if (e.target.documentElement.scrollTop + e.target.documentElement.clientHeight - 150 >= (rectItem.top + yPage)) {
                wrapperService.querySelector('.main__page-services__layer-1').classList.remove('initial');
                wrapperService.querySelector('.main__page-services__layer-1').classList.add('showed');
                wrapperService.querySelector('.main__page-services__layer-2').classList.remove('initial');
                wrapperService.querySelector('.main__page-services__layer-2').classList.add('showed');
                if (wrapperService.querySelector('.main__page-services__layer-3') !== null && wrapperService.querySelector('.main__page-services__layer-3') !== undefined) {
                    wrapperService.querySelector('.main__page-services__layer-3').classList.remove('initial');
                    wrapperService.querySelector('.main__page-services__layer-3').classList.add('showed');
                }
            }
            if (scrollFromTopToMiddle + 150 >= (rectItem.top + rectItem.height / 2 + yPage)) {
                wrapperService.querySelector('.main__page-service__suptitle').classList.remove('initial');
                wrapperService.querySelector('.main__page-service__title').classList.remove('initial');
                wrapperService.querySelector('.main__page-service__description').classList.remove('initial');
                setTimeout(() => wrapperService.querySelector('.main__page-service__button').classList.remove('initial'), 600);
            }
        }

        if (document.querySelector('.main__page-description__title') !== null) {
            let rectMainDescription = document.querySelector('.main__page-description__title').getBoundingClientRect();
            if (
                e.target.documentElement.scrollTop + e.target.documentElement.clientHeight - 350 >= (rectMainDescription.top + yPage) &&
                document.querySelector('.main__page-description__title').classList.contains('initial')
            ) {
                document.querySelector('.main__page-description__title').classList.remove('initial');
                document.querySelector('.main__page-description__text').classList.remove('initial');
            }
        }
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].classList.remove('services');
        document.getElementsByTagName('body')[0].classList.add('index');
        window.addEventListener('scroll', this.onScroll);

        const supportPageOffset = window.pageYOffset !== undefined;
        const isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        const yPage = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

        if (window.innerWidth > 1040) {
            let startVideo = document.getElementById("started-top-video"), current = this;

            if (startVideo !== null) {
                startVideo.onended = function() {
                    current.cycleTopVideo();
                };
                startVideo.addEventListener('play', () => {
                    setTimeout(() => {
                        if (document.querySelector('.main__page-banner__title') !== null) {
                            document.querySelector('.main__page-banner__title').classList.remove('initial');
                            setTimeout(() => {
                                if (document.querySelector('.main__page-banner__button') !== null) {
                                    document.querySelector('.main__page-banner__button').classList.remove('initial');
                                }
                            }, 200);
                        }
                    }, 4700);
                });
            }
        } else {
            document.querySelector('.main__page-banner__title').classList.remove('initial');
            setTimeout(() => {
                if (document.querySelector('.main__page-banner__button') !== null) {
                    document.querySelector('.main__page-banner__button').classList.remove('initial');
                }
            }, 200);
        }

        let videoReviews = document.getElementsByClassName('main__page-reviews__item-wrapper');
        for (let i = 0; i < videoReviews.length; i++) {
            videoReviews[i].addEventListener('click', () => {
                if (window.innerWidth < 767) {
                    let currentSlide = document.querySelector('.slick-slide.slick-current').getAttribute('data-index');
                    let videoUrl = '', videoPoster = '';
                    switch (currentSlide) {
                        case '0':
                            videoUrl = '/_video/aspec-lider_review.mp4';
                            videoPoster = '/_images/aspeclider_cover.jpg';
                            break;
                        case '1':
                            videoUrl = '/_video/dinaplus_review.mp4';
                            videoPoster = '/_images/dinaplus_cover.jpg';
                            break;
                        case '2':
                            videoUrl = '/_video/millenium_review.mp4';
                            videoPoster = '/_images/millenium_cover.jpg';
                            break;
                    }
                    this.setState({
                        videoUrl: videoUrl,
                        videoPoster: videoPoster,
                    });
                    this.player.setState({
                        paused: true,
                        muted: false,
                        length: null,
                        formattedLength: null,
                        currentTime: null,
                        formattedTime: null,
                        volume: 0.5,
                        videoId: "review-video",
                        hideStub: false,
                    });
                    document.querySelector('.main__page-reviews__video-shadow').classList.add('showed');
                    this.player.playVideoOnStart();
                } else {
                    let currentSlide = document.querySelector('.slick-slide.slick-current').getAttribute('data-index');
                    if (currentSlide !== videoReviews[i].getAttribute('data-index')) {
                        if (currentSlide == 2 && videoReviews[i].getAttribute('data-index') == 0) {
                            this.slider.slickNext();
                        } else if (currentSlide == 0 && videoReviews[i].getAttribute('data-index') == 2) {
                            this.slider.slickPrev();
                        } else if (currentSlide < videoReviews[i].getAttribute('data-index')) {
                            this.slider.slickNext();
                        } else {
                            this.slider.slickPrev();
                        }
                    } else {
                        let videoUrl = '', videoPoster = '';
                        switch (currentSlide) {
                            case '0':
                                videoUrl = '/_video/aspec-lider_review.mp4';
                                videoPoster = '/_images/aspeclider_cover.jpg';
                                break;
                            case '1':
                                videoUrl = '/_video/dinaplus_review.mp4';
                                videoPoster = '/_images/dinaplus_cover.jpg';
                                break;
                            case '2':
                                videoUrl = '/_video/millenium_review.mp4';
                                videoPoster = '/_images/millenium_cover.jpg';
                                break;
                        }
                        this.setState({
                            videoUrl: videoUrl,
                            videoPoster: videoPoster,
                        });
                        this.player.setState({
                            paused: true,
                            muted: false,
                            length: null,
                            formattedLength: null,
                            currentTime: null,
                            formattedTime: null,
                            volume: 0.5,
                            videoId: "review-video",
                            hideStub: false,
                        });
                        document.querySelector('.main__page-reviews__video-shadow').classList.add('showed');
                        this.player.playVideoOnStart();
                    }
                }
            });
        }

        let servicesBanners = document.getElementsByClassName('main__page-service');
        let scrollFromTopToMiddle = document.documentElement.scrollTop + document.documentElement.clientHeight / 2;
        for (let i = 0; i < servicesBanners.length; i++) {
            let wrapperService = servicesBanners[i],
                layerOne = wrapperService.querySelector('.main__page-services__layer-1'),
                layerTwo = wrapperService.querySelector('.main__page-services__layer-2'),
                layerThree = wrapperService.querySelector('.main__page-services__layer-3');

            let movementOne = -20, movementTwo = -35, movementThree = -50;

            if (window.innerWidth > 1040) {
                wrapperService.addEventListener('mousemove',function(e) {
                    let xFirst = (e.clientX - wrapperService.clientWidth / 2) / wrapperService.clientWidth * movementOne,
                        yFirst = (e.clientY - wrapperService.clientHeight / 2) / wrapperService.clientHeight * movementOne,
                        xSecond = (e.clientX - wrapperService.clientWidth / 2) / wrapperService.clientWidth * movementTwo,
                        ySecond = (e.clientY - wrapperService.clientHeight / 2) / wrapperService.clientHeight * movementTwo;

                    gsap.to(layerOne, 1, {x: xFirst, y: yFirst});
                    gsap.to(layerTwo, 1, {x: xSecond, y: ySecond});

                    if (layerThree !== null) {
                        let xThree = (e.clientX - wrapperService.clientWidth / 2) / wrapperService.clientWidth * movementThree,
                            yThree = (e.clientY - wrapperService.clientHeight / 2) / wrapperService.clientHeight * movementThree;

                        gsap.to(layerThree, 1, {x: xThree, y: yThree});
                    }
                });
            }

            let rectItem = servicesBanners[i].getBoundingClientRect();
            if (document.documentElement.scrollTop + document.documentElement.clientHeight - 150 >= (rectItem.top + yPage)) {
                layerOne.classList.remove('initial');
                layerOne.classList.add('showed');
                layerTwo.classList.remove('initial');
                layerTwo.classList.add('showed');
                if (layerThree !== null && layerThree !== undefined) {
                    layerThree.classList.remove('initial');
                    layerThree.classList.add('showed');
                }
            }
            if (scrollFromTopToMiddle + 150 >= (rectItem.top + rectItem.height / 2 + yPage)) {
                wrapperService.querySelector('.main__page-service__suptitle').classList.remove('initial');
                wrapperService.querySelector('.main__page-service__title').classList.remove('initial');
                wrapperService.querySelector('.main__page-service__description').classList.remove('initial');
                setTimeout(() => wrapperService.querySelector('.main__page-service__button').classList.remove('initial'), 600);
            }
            // if (
            //     this.isElementInViewport(wrapperService.querySelector('.main__page-service__content')) &&
            //     wrapperService.querySelector('.main__page-service__title').classList.contains('initial')
            // ) {
            //     wrapperService.querySelector('.main__page-service__suptitle').classList.remove('initial');
            //     wrapperService.querySelector('.main__page-service__title').classList.remove('initial');
            //     wrapperService.querySelector('.main__page-service__description').classList.remove('initial');
            //     setTimeout(() => wrapperService.querySelector('.main__page-service__button').classList.remove('initial'), 600);
            // }
        }

        let videoReviewsClose = document.querySelector('.main__page-reviews__video-close');
        videoReviewsClose.addEventListener('click', (e) => {
            e.preventDefault();
            this.player.pauseVideo();
            document.querySelector('.main__page-reviews__video-shadow').classList.remove('showed');
        });
        document.querySelector('.main__page-reviews__video-shadow__dump').addEventListener('click', (e) => {
            e.preventDefault();
            this.player.pauseVideo();
            document.querySelector('.main__page-reviews__video-shadow').classList.remove('showed');
        });

        let rectMainDescription = document.querySelector('.main__page-description__title').getBoundingClientRect();
        if (
            document.documentElement.scrollTop + document.documentElement.clientHeight - 350 >= (rectMainDescription.top + yPage) &&
            document.querySelector('.main__page-description__title').classList.contains('initial')
        ) {
            document.querySelector('.main__page-description__title').classList.remove('initial');
            document.querySelector('.main__page-description__text').classList.remove('initial');
        }
    }

    cycleTopVideo = () => {
        let video = document.getElementById('cycle-top-video');
        video.classList.add('show');
        setInterval(function () {
            video.play();
        }, 5000);
    }

    render() {
        return (
            <section className="main__page">
                <Helmet>
                    <title>Интернет-лаборатория Пластилин AutoTeam | Интернет продвижение, SEO, поддержка сайта</title>
                    <meta name="description" content="Закажите эффективное интернет продвижение сайта автомобильной тематики с гарантией результата ✔ SEO-продвижение ✔ Интернет-реклама ✔ Поддержка сайта ★ Интернет-лаборатория Пластилин AutoTeam" />
                </Helmet>
                <div className="main__page-banner">
                    <div className="main__page-banner__videos">
                        {window.innerWidth < 767 && <img src={"/_images/main_mobile.jpg"} alt=""/> }

                        <video autoPlay={window.innerWidth > 1040 ? true : false} muted="muted" preload="auto" src="/_video/main_start_video.mp4" id="started-top-video" poster="/_images/main_poster.jpg" />
                        <video muted="muted" preload="auto" src="/_video/main_cycle.mp4" id="cycle-top-video"/>
                    </div>
                    <div className="main__page-banner__content">
                        <div className="wrapper">
                            <h1 className="main__page-banner__title h2 initial">
                                Помогаем выполнять план по <span>продажам автомобилей</span>, услуг сервиса.
                            </h1>
                            <NavLink to="/about" className="main__page-banner__button arrow-button initial"><span>Подробнее</span></NavLink>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main__page-description">
                        <div className="main__page-description__title initial">Повышаем эффективность маркетинга дилерских центров</div>
                        <div className="main__page-description__text text initial">
                            AutoTeam - агентство интернет-маркетинга, с 2016 года мы внедряем инструменты электронной коммерции для автомобильного бизнеса. Комплексный маркетинг включает услуги: поддержку и развитие сайтов, ведение рекламных кампаний, поисковое продвижение, внедрение сквозной аналитики.  В нашем портфолио представлены дилерские центры марок ŠKODA, Volkswagen, Ford, Chery, Great Wall.
                        </div>
                    </div>
                </div>
                <div className="main__page-services">
                    <div className="wrapper">
                        <div className="main__page-service">
                            <div className="main__page-service__content">
                                <div className="main__page-service__suptitle text initial">Услуги</div>
                                <div className="main__page-service__title initial">Поддержка сайта</div>
                                <div className="main__page-service__description text initial">
                                    Поддерживаем сайты наших клиентов, помогаем создавать цифровой контент.
                                </div>
                                <NavLink to="/support" className="main__page-service__button arrow-button initial"><span>Подробнее</span></NavLink>
                            </div>
                            <div className="main__page-service__banner-wrapper">
                                <div className="main__page-service__banner">
                                    <div className="main__page-services__circle" />
                                    <img src="/_images/support_main_layer_1.png" className="main__page-services__layer-1 initial" alt="" />
                                    <img src="/_images/support_main_layer_2.png" className="main__page-services__layer-2 initial" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="main__page-service left-banner">
                            <div className="main__page-service__banner-wrapper">
                                <div className="main__page-service__banner">
                                    <div className="main__page-services__circle" />
                                    <img src="/_images/commercial_main_layer_1.png" className="main__page-services__layer-1 initial" alt="" />
                                    <img src="/_images/commercial_main_layer_2.png" className="main__page-services__layer-2 initial" alt="" />
                                </div>
                            </div>
                            <div className="main__page-service__content">
                                <div className="main__page-service__suptitle text initial">Услуги</div>
                                <div className="main__page-service__title initial">Реклама</div>
                                <div className="main__page-service__description text initial">
                                    Настраиваем рекламу для автодилеров. Помогаем найти новых клиентов из поисковых систем.
                                </div>
                                <NavLink to="/advertising" className="main__page-service__button arrow-button initial"><span>Подробнее</span></NavLink>
                            </div>
                        </div>
                        <div className="main__page-service">
                            <div className="main__page-service__content">
                                <div className="main__page-service__suptitle text initial">Услуги</div>
                                <div className="main__page-service__title initial">SEO оптимизация</div>
                                <div className="main__page-service__description text initial">
                                    Готовим сайт для продвижения в ТОП поисковых систем и получения целевого трафика.
                                </div>
                                <NavLink to="/seo" className="main__page-service__button arrow-button initial"><span>Подробнее</span></NavLink>
                            </div>
                            <div className="main__page-service__banner-wrapper">
                                <div className="main__page-service__banner">
                                    <div className="main__page-services__circle" />
                                    <img src="/_images/seo_main_layer_1.png" className="main__page-services__layer-1 initial" alt="" />
                                    <img src="/_images/seo_main_layer_2.png" className="main__page-services__layer-2 initial" alt="" />
                                    <img src="/_images/seo_main_layer_3.png" className="main__page-services__layer-3 initial" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__page-reviews">
                    <div className="main__page-reviews__title">Клиенты о работе с нами</div>
                    <div className="main__page-reviews__description text">
                        Мы любим своих клиентов, а они не стесняются рассказать о результатах работы с нами.
                    </div>
                    <div className="main__page-reviews__slider">
                        <Slider ref={slider => (this.slider = slider)} {...this.sliderSettings}>
                            <div className="main__page-reviews__item-wrapper" data-index="0">
                                <div className="main__page-reviews__item">
                                    <div className="main__page-reviews__item-image">
                                        <img src="/_images/aspeclider_cover.jpg" alt="" />
                                        <div className="main__page-reviews__item-play">
                                            <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.517683 0.138764C0.677412 0.0463261 0.856175 0 1.03521 0C1.21375 0 1.39246 0.0463261 1.55289 0.138764L13.8913 9.10372C14.211 9.28843 14.409 9.63041 14.409 10.0002C14.409 10.3699 14.2115 10.7118 13.8913 10.8963L1.55289 19.8616C1.23268 20.0461 0.837945 20.0461 0.517951 19.8616C0.19769 19.6765 0 19.3344 0 18.9649V1.0351C0 0.665562 0.197368 0.323639 0.517683 0.138764Z" fill="currentColor" fillRule="evenodd"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="main__page-reviews__item-content">
                                        <div className="main__page-reviews__item-name">Юлия Перевозчикова</div>
                                        <div className="main__page-reviews__item-description text">
                                            Маркетолог компании АСПЭК Лидер, официального дилера ŠKODA в городе Ижевск.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main__page-reviews__item-wrapper" data-index="1">
                                <div className="main__page-reviews__item">
                                    <div className="main__page-reviews__item-image">
                                        <img src="/_images/dinaplus_cover.jpg" alt="" />
                                        <div className="main__page-reviews__item-play">
                                            <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.517683 0.138764C0.677412 0.0463261 0.856175 0 1.03521 0C1.21375 0 1.39246 0.0463261 1.55289 0.138764L13.8913 9.10372C14.211 9.28843 14.409 9.63041 14.409 10.0002C14.409 10.3699 14.2115 10.7118 13.8913 10.8963L1.55289 19.8616C1.23268 20.0461 0.837945 20.0461 0.517951 19.8616C0.19769 19.6765 0 19.3344 0 18.9649V1.0351C0 0.665562 0.197368 0.323639 0.517683 0.138764Z" fill="currentColor" fillRule="evenodd"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="main__page-reviews__item-content">
                                        <div className="main__page-reviews__item-name">Григорьева Ирина</div>
                                        <div className="main__page-reviews__item-description text">
                                            Маркетолог компании Дина Плюс, официального дилера ŠKODA в городе Тюмень.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main__page-reviews__item-wrapper" data-index="2">
                                <div className="main__page-reviews__item">
                                    <div className="main__page-reviews__item-image">
                                        <img src="/_images/millenium_cover.jpg" alt="" />
                                        <div className="main__page-reviews__item-play">
                                            <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.517683 0.138764C0.677412 0.0463261 0.856175 0 1.03521 0C1.21375 0 1.39246 0.0463261 1.55289 0.138764L13.8913 9.10372C14.211 9.28843 14.409 9.63041 14.409 10.0002C14.409 10.3699 14.2115 10.7118 13.8913 10.8963L1.55289 19.8616C1.23268 20.0461 0.837945 20.0461 0.517951 19.8616C0.19769 19.6765 0 19.3344 0 18.9649V1.0351C0 0.665562 0.197368 0.323639 0.517683 0.138764Z" fill="currentColor" fillRule="evenodd"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="main__page-reviews__item-content">
                                        <div className="main__page-reviews__item-name">Юлия Андриянова</div>
                                        <div className="main__page-reviews__item-description text">
                                            Руководитель отдела маркетинга Миллениум групп Кострома.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="main__page-reviews__video-shadow">
                        <div className="main__page-reviews__video-shadow__dump"/>
                        <div className="main__page-reviews__video">
                            <VideoPlayer video={{
                                img: this.state.videoPoster,
                                src: this.state.videoUrl
                            }} id="review-video" ref={player => (this.player = player)}/>
                            <button className="main__page-reviews__video-close">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.8323 9.99994L19.6199 2.21211C20.1267 1.70554 20.1267 0.886491 19.6199 0.379925C19.1133 -0.126642 18.2943 -0.126642 17.7877 0.379925L9.99988 8.16776L2.21228 0.379925C1.70548 -0.126642 0.886669 -0.126642 0.380103 0.379925C-0.126701 0.886491 -0.126701 1.70554 0.380103 2.21211L8.1677 9.99994L0.380103 17.7878C-0.126701 18.2943 -0.126701 19.1134 0.380103 19.62C0.632555 19.8726 0.964493 19.9996 1.29619 19.9996C1.62789 19.9996 1.95959 19.8726 2.21228 19.62L9.99988 11.8321L17.7877 19.62C18.0404 19.8726 18.3721 19.9996 18.7038 19.9996C19.0355 19.9996 19.3672 19.8726 19.6199 19.62C20.1267 19.1134 20.1267 18.2943 19.6199 17.7878L11.8323 9.99994Z"
                                        fill="white"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Home;