import React from 'react';
import {Helmet} from "react-helmet";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ReviewItem from "../components/review_item";

class About extends React.Component {
    state = {
        animatedShowBrands: false,
        animatedShowText: false,
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].classList.remove('services');
        document.getElementsByTagName('body')[0].classList.remove('index');

        const anchor = window.location.hash;
        if (anchor) {
            const domElement = document.querySelector(anchor);
            if (domElement) {
                domElement.scrollIntoView();
            }
        }
        window.addEventListener('scroll', this.onScroll);

        if (document.getElementsByClassName('about__title').length > 0) {
            const supportPageOffset = window.pageYOffset !== undefined;
            const isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
            const yPage = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

            let scrollFromTop = document.documentElement.scrollTop + document.documentElement.clientHeight,
                rectBrands = document.getElementsByClassName('about__title')[0].getBoundingClientRect();
            if (scrollFromTop - 250 >= (rectBrands.top + yPage) && !this.state.animatedShowText) {
                this.setState({
                    animatedShowText: true
                });
                document.querySelector('.about__title').classList.remove('initial');
                document.querySelector('.about__description').classList.remove('initial');
            }

        }
    }

    sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    reviewItems = [
        {
            'name': 'Дарья Храмова',
            'position': 'менеджер по маркетингу компании"Саранскмоторс Авто", официального дилера SKODA',
            'title': '',
            'description': 'Хорошая IT-компания с грамотными специалистами и адекватными расценками.' +
                'Достаточно долгое время сотрудничаем с данной организацией в части SEO.' +
                'Никогда не было никаких замечании или нареканий по работе.<br><br>' +
                'Обновления сайта всегда производится вовремя, отчет по работам соответствует стандартам ŠKODA. Очень нравится, что планы работ на следующий месяц присылают заранее.' +
                'Всегда есть отчетность о проделанной работе за отдельный период - это удобно.' +
                'Сотрудники профессиональные и вежливые, всегда готовы помочь.<br><br>' +
                'АвтоТим - агентство комфортное для сотрудничества и работы.',
            'logo': '/_images/skoda_review_icon.svg'
        },
        {
            'name': 'Городилова Екатерина',
            'position': 'маркетолог компании "Авто-Дина", официального дилера Volkswagen',
            'title': '',
            'description': 'Сотрудничать с Пластилин АвтоТим наша компания начала в 2021 году.<br><br>' +
                'Обратились для ведения контекстной рекламы в Google, Яндекс и SEO-оптимизацией. Данную веб-студию отличает индивидуальный гибкий подход к поставленным задачам, скорость, клиентоориентированность в реализации проектов.<br><br>' +
                'Особенно хочется отметить профессионализм и дружелюбие сотрудников. Желаем всему коллективу творческих успехов и процветания!',
            'logo': '/_images/volkswagen_review_icon.svg'
        },
    ]

    onScroll = (e) => {
        let scrollFromTop = e.target.documentElement.scrollTop + e.target.documentElement.clientHeight;

        const supportPageOffset = window.pageYOffset !== undefined;
        const isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        const yPage = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

        if (document.getElementsByClassName('about__brands').length > 0) {
            let rectBrands = document.getElementsByClassName('about__brands')[0].getBoundingClientRect();
            if (scrollFromTop - 250 >= (rectBrands.top + yPage) && !this.state.animatedShowBrands) {
                this.setState({
                    animatedShowBrands: true
                });
                this.animateShowBrands();
            }

        }

        if (document.getElementsByClassName('about__title').length > 0) {
            let rectBrands = document.getElementsByClassName('about__title')[0].getBoundingClientRect();
            if (scrollFromTop - 250 >= (rectBrands.top + yPage) && !this.state.animatedShowText) {
                this.setState({
                    animatedShowText: true
                });
                document.querySelector('.about__title').classList.remove('initial');
                document.querySelector('.about__description').classList.remove('initial');
            }
        }
    }

    animateShowBrands = () => {
        const brandItems = document.getElementsByClassName('about__brands-item');
        for (let i = 0; i < brandItems.length; i++) {
            setTimeout(function () {
                brandItems[i].classList.remove('initial')
            }, 200 * i);
        }
    }

    render() {
        return (
            <section className="about__page">
                <Helmet>
                    <title>О проекте AutoTeam  | Интернет-лаборатория Пластилин</title>
                    <meta name="description" content="Проект AutoTeam - это команда опытных специалистов, с 2016 года занимающихся продвижением и поддержкой сайтов автомобильной тематики. Наши клиенты - ведущие автомобильные бренды и дилерские центры. ★ Интернет-лаборатория Пластилин" />
                </Helmet>
                <div className="wrapper">
                    <h1 className="about__title initial">
                        Более 5 лет мы <span>повышаем продажи автодилеров</span> и гордимся своей работой
                    </h1>
                    <div className="about__description initial text">
                        <p>
                            Мы интернет-агентство, занимающееся поддержкой и продвижением сайтов автотематики с 2016 года. Пять лет назад мы разработали свой первый автомобильный сайт и спустя 3 года, получив опыт работы с дилерскими платформами, решили выделить отдельное направление.
                        </p>
                        <p>
                            Сейчас AutoTeam - это команда специалистов в сфере интернет-маркетинга для автобизнеса. Ведущие автомобильные бренды и их дилеры выбрали наши услуги для увеличения онлайн продаж. В нашем портфолио более 2-х десятков дилерских центров и 6 марок.
                        </p>
                    </div>
                    <div className="about__brands">
                        <div className="about__brands-line">
                            <img src="/_images/skoda.svg" alt="ŠKODA" className="about__brands-item initial"/>
                            <img src="/_images/vw.svg" alt="Volkswagen" className="about__brands-item initial"/>
                            <img src="/_images/ford.svg" alt="Ford" className="about__brands-item initial"/>
                        </div>
                        <div className="about__brands-line">
                            <img src="/_images/great_wall.svg" alt="Great Wall" className="about__brands-item initial"/>
                            <img src="/_images/chery.svg" alt="Chery" className="about__brands-item initial"/>
                            <img src="/_images/hyndai.svg" alt="Chery" className="about__brands-item initial"/>
                        </div>
                    </div>
                    <div className="about__review" id="reviews">
                        <h2 className="about__review-title">Нас рекомендуют</h2>
                        <div className="about__review-description text">
                            С клиентам у нас долгие и доверительные отношения. Например, с дилерами ŠKODA мы работаем с 2018 года, более 4 лет они доверяют нам свои задачи.
                        </div>
                        <div className="about__review-slider">
                            <Slider {...this.sliderSettings}>
                                {
                                    this.reviewItems.map((review, i) => {
                                        return (<ReviewItem review={review} key={`review_` + i}/>);
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default About;