import React from 'react';
import InputMask from 'react-input-mask';
import {NavLink} from "react-router-dom";

class FooterForm extends React.Component {
    componentDidMount() {
        const elements = document.getElementsByClassName("page__footer-input");
        for (let i = 0; i < elements.length; i++) {
            elements[i].addEventListener('focus', () => {
                elements[i].classList.remove('error-input');
            })
        }
        document.getElementsByClassName('forms-result__close')[0].addEventListener('click', () => {
            document.getElementsByClassName('forms-result__shadow')[0].classList.remove('showed');
        });
    }

    sendFormData = async () => {
        let formResult = document.getElementsByClassName('footer__form-result')[0];
        formResult.classList.remove('show');

        let sendObject = {};
        let sendAccess = true;

        if (document.getElementById('page-footer-name').value.length > 0) {
            sendObject.name = document.getElementById('page-footer-name').value;
        } else {
            document.getElementById('page-footer-name').classList.add('error-input');
            sendAccess = false;
        }

        if (document.getElementById('page-footer-dc').value.length > 0) {
            sendObject.dc = document.getElementById('page-footer-dc').value;
        }

        if (document.getElementById('page-footer-site').value.length > 0) {
            sendObject.site = document.getElementById('page-footer-site').value;
        }

        if (document.getElementById('page-footer-phone').value.length > 0) {
            if (document.getElementById('page-footer-phone').value.indexOf('_') > -1) {
                document.getElementById('page-footer-phone').classList.add('error-input');
                sendAccess = false;
            } else {
                sendObject.phone = document.getElementById('page-footer-phone').value;
            }
        } else {
            document.getElementById('page-footer-phone').classList.add('error-input');
            sendAccess = false;
        }

        if (document.getElementById('page-footer-comment').value.length > 0) {
            sendObject.description = document.getElementById('page-footer-comment').value;
        }

        sendObject.service = document.getElementById('page-footer-service').value;
        sendObject.tariff = document.getElementById('page-footer-tariff').value;

        let id = '';
        if (getCookie('uid')) {
            id = getCookie('uid');
        } else {
            let number = Math.random() // 0.9394456857981651
            number.toString(36); // '0.xtis06h6'
            let id = number.toString(36).substr(2, 9); // 'xtis0
            document.cookie = 'uid=' + id;
        }

        sendObject.id = id;
        sendObject.method = 'request';

        if (sendAccess) {
            let xhr = new XMLHttpRequest();
            xhr.open("POST", '/ajax.php', true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let data = JSON.parse(xhr.responseText);
                        if (data.success) {
                            const inputs = document.getElementsByClassName("page__footer-input");
                            for (let i = 0; i < inputs.length; i++) {
                                inputs[i].value = '';
                            }
                            document.getElementById('page-footer-comment').value = '';
                            document.getElementById("task-file").value = "";
                            document.querySelector('.footer-form__files-list').innerHTML = '';
                            document.getElementsByClassName('forms-result__shadow')[0].classList.add('showed');
                        } else {
                            formResult.textContent = 'Произошла ошибка, попробуйте позже';
                            formResult.classList.add('show');
                        }
                    } else {
                        formResult.textContent = 'Произошла ошибка, попробуйте позже';
                        formResult.classList.add('show');
                    }
                }
            };
            xhr.send('param=' + JSON.stringify(sendObject));
        }
    }

    photoUpload = async () => {
        let photos = document.getElementById("task-file").files;
        let formData = new FormData();
        if (photos.length > 0) {
            for (let i = 0; i < photos.length; i++) {
                formData.append("files[]", photos[i])
            }
        }
        formData.append('method', 'files');

        let id = '';
        if (getCookie('uid')) {
            id = getCookie('uid');
        } else {
            let number = Math.random()
            number.toString(36);
            let id = number.toString(36).substr(2, 9);
            document.cookie = 'uid=' + id;
        }
        formData.append('id', id);
        await fetch('/ajax.php', {method: "POST", body: formData}).then((response) => {
            return response.json();
        }).then((data) => {
            document.getElementById("task-file").value = "";
            if (data.files.length > 0) {
                for (let i = 0; i < data.files.length; i++) {
                    document.getElementsByClassName('footer-form__files-list')[0].insertAdjacentHTML(
                        'beforeend', '<div class="footer-form__files-item" data-url="' + data.files[i].url + '">' + data.files[i].name + '</div>'
                    );
                }
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="footer-form__section">
                    <div className="wrapper">
                        <div className="footer-form">
                            <div className="footer-form__title">Оставить заявку</div>
                            <div className="footer-form__fields">
                                <div className="footer-form__fields-title">Контактные данные</div>
                                <input type="hidden" id="page-footer-service"/>
                                <input type="hidden" id="page-footer-tariff"/>
                                <div className="footer-form__fields-inputs">
                                    <input type="text" id="page-footer-name" className="page__footer-input" placeholder="ФИО" />
                                    <input type="text" id="page-footer-dc" className="page__footer-input" placeholder="ДЦ" />
                                </div>
                                <div className="footer-form__fields-inputs">
                                    <input type="text" id="page-footer-site" className="page__footer-input" placeholder="Сайт" />
                                    <InputMask className="page__footer-input" id="page-footer-phone" placeholder="Телефон" mask="+7 999 999 99 99" />
                                </div>
                                <textarea className="page__footer-comment" placeholder="Комментарий" id="page-footer-comment"/>
                                <div className="footer-form__files">
                                    <div className="footer-form__files-list"/>
                                    <input type="file" id="task-file" onChange={this.photoUpload.bind(this)} multiple={true}/>
                                    <label htmlFor="task-file" className="footer-form__task-file">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.15167 13.9332C1.88337 14.6649 2.85737 15.0846 3.82394 15.0846C4.63676 15.0846 5.38222 14.7867 5.92304 14.246L12.2472 7.92174C12.6394 7.53407 12.8331 6.99416 12.7913 6.40307C12.7097 5.2497 11.6664 4.20649 10.5131 4.12481C10.4611 4.12116 10.4087 4.11926 10.3573 4.11926C9.82888 4.11926 9.34364 4.3157 8.99105 4.67232L3.4608 10.2026C3.28307 10.3803 3.19219 10.6328 3.21142 10.8953C3.22949 11.1419 3.34034 11.3785 3.52333 11.5615C3.72324 11.7615 3.99176 11.8761 4.26002 11.8761C4.49884 11.8761 4.71986 11.7866 4.88242 11.6241L10.4161 6.09043C10.4471 6.05913 10.4862 6.04445 10.5368 6.04445C10.5427 6.04445 10.5487 6.04463 10.5545 6.04507C10.6997 6.05571 10.8604 6.21645 10.8712 6.36343C10.8738 6.40155 10.8687 6.45744 10.8287 6.49699L4.50145 12.8242C4.28507 13.0406 3.98156 13.1598 3.64677 13.1598C3.2175 13.1598 2.78072 12.9691 2.44824 12.6367C2.1426 12.331 1.95823 11.9434 1.92909 11.5451C1.90135 11.1666 2.01909 10.825 2.26066 10.5834L9.37533 3.46871C9.78091 3.06315 10.3467 2.83979 10.9684 2.83979C11.7467 2.83979 12.5363 3.183 13.1347 3.78143C14.2573 4.9041 14.3976 6.59056 13.4474 7.54076L7.91374 13.0744C7.73601 13.2521 7.64513 13.5046 7.66437 13.7671C7.68243 14.0137 7.79328 14.2504 7.97627 14.4334C8.17618 14.6333 8.44471 14.7479 8.71299 14.7479C8.95182 14.7479 9.17281 14.6584 9.33537 14.496L14.869 8.96229C16.5341 7.2972 16.3377 4.39138 14.4311 2.48478C13.4335 1.48716 12.1069 0.915039 10.7915 0.915039V1.09394L10.7914 0.915039C9.69123 0.915127 8.68362 1.31712 7.95367 2.04703L0.839042 9.16165C0.237133 9.76359 -0.0574929 10.597 0.0093014 11.5088C0.0746049 12.4009 0.480369 13.2619 1.15167 13.9332Z" fill="currentColor"/>
                                        </svg>
                                        <span>Прикрепить файл</span>
                                    </label>
                                </div>
                                <div className="footer-form__send">
                                    <button id="footer-form__send-button" onClick={this.sendFormData.bind(this)}>Отправить запрос</button>
                                    <div className="footer-form__send-description">
                                        Нажимая на кнопку, вы даете <a href="/personal.pdf" target="_blank" rel="noreferrer">согласие на обработку персональных данных</a> и соглашаетесь с политикой конфиденциальности.
                                    </div>
                                </div>
                                <div className="footer__form-result">Заполните ваше имя</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="forms-result__shadow">
                    <div className="forms-result__wrapper">
                        <div className="wrapper">
                            <div className="forms-result">
                                <div className="forms-result__title">Заявка отправлена</div>
                                <div className="forms-result__content">
                                    <div className="forms-result__text text">
                                        Ожидайте, скоро мы с вами свяжемся, а пока можете вернуться на главную страницу.
                                    </div>
                                    <NavLink to="/" className="forms-result__link arrow-button" onClick={() => {
                                        document.getElementsByClassName('forms-result__shadow')[0].classList.remove('showed');
                                    }}><span>На главную</span></NavLink>
                                </div>
                                <button className="forms-result__close">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8323 9.99994L19.6199 2.21211C20.1267 1.70554 20.1267 0.886491 19.6199 0.379925C19.1133 -0.126642 18.2943 -0.126642 17.7877 0.379925L9.99988 8.16776L2.21228 0.379925C1.70548 -0.126642 0.886669 -0.126642 0.380103 0.379925C-0.126701 0.886491 -0.126701 1.70554 0.380103 2.21211L8.1677 9.99994L0.380103 17.7878C-0.126701 18.2943 -0.126701 19.1134 0.380103 19.62C0.632555 19.8726 0.964493 19.9996 1.29619 19.9996C1.62789 19.9996 1.95959 19.8726 2.21228 19.62L9.99988 11.8321L17.7877 19.62C18.0404 19.8726 18.3721 19.9996 18.7038 19.9996C19.0355 19.9996 19.3672 19.8726 19.6199 19.62C20.1267 19.1134 20.1267 18.2943 19.6199 17.7878L11.8323 9.99994Z"
                                            fill="black"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const getCookie = name => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export default FooterForm;