import React from 'react';

class ServiceVideo extends React.Component {
    componentDidMount() {
        if (document.getElementById('service-top-video-start') !== undefined) {
            let startVideo = document.getElementById('service-top-video-start');

            if (startVideo !== null) {
                startVideo.onended = function() {
                    document.getElementById('service-top-video-start').style.display = 'none';
                    document.getElementById('service-top-video').style.display = 'block';
                    document.getElementById('service-top-video').play();
                };
            }
        }
    }

    render() {
        return (
            <div className="service__top-banner__wrapper">
                {
                    this.props.type === 'seo' &&
                    <video autoPlay={false} loop muted="muted" preload="auto" poster="/_images/seo_poster.jpg"
                           src="/_video/seo_service_top_2.mp4" id="service-top-video"></video>
                }
                {
                    this.props.type === 'seo' &&
                    <video autoPlay={window.innerWidth > 1040 ? true : false} muted="muted" preload="auto"
                           src="/_video/seo_appear.mp4" id="service-top-video-start"></video>
                }
                {
                    (this.props.type === 'seo' && window.innerWidth < 767) &&
                    <img src={"/_images/seo_mobile.png"} alt={""} />
                }
                {
                    this.props.type === 'advertising' &&
                    <video autoPlay={false} loop muted="muted" preload="auto" poster="/_images/commercial_poster_1.jpg"
                           src="/_video/commercial_service_top_2.mp4" id="service-top-video"></video>
                }
                {
                    this.props.type === 'advertising' &&
                    <video autoPlay={window.innerWidth > 1040 ? true : false} muted="muted" preload="auto"
                           src="/_video/commercial_appear.mp4" id="service-top-video-start"></video>
                }
                {
                    (this.props.type === 'advertising' && window.innerWidth < 767) &&
                    <img src={"/_images/commercial_mobile.png"} alt={""} />
                }
                {
                    this.props.type === 'support' &&
                    <video autoPlay={false} loop muted="muted" preload="auto" poster="/_images/support_poster_1.jpg"
                           src="/_video/support_service_top_2.mp4" id="service-top-video"></video>
                }
                {
                    this.props.type === 'support' &&
                    <video autoPlay={window.innerWidth > 1040 ? true : false} muted="muted" preload="auto"
                           src="/_video/support_appear.mp4" id="service-top-video-start"></video>
                }
                {
                    (this.props.type === 'support' && window.innerWidth < 767) &&
                    <img src={"/_images/support_mobile.png"} alt={""} />
                }
            </div>
        )
    }
}

export default ServiceVideo;