import React from 'react';
import AnalyticsIcon from '../svg/seo_service_analytics.svg'
import AuditIcon from '../svg/seo_service_audit.svg'
import MonitorIcon from '../svg/seo_service_monitor.svg'
import SearchIcon from '../svg/seo_service_search.svg'
import TechIcon from '../svg/seo_service_tech.svg'
import TextIcon from '../svg/seo_service_text.svg'
import Context from '../svg/adv_context.svg'
import Converse from '../svg/adv_converse.svg'
import Automatic from '../svg/adv_automatic.svg'
import Kpi from '../svg/adv_kpi.svg'
import Mouse from '../svg/support_mouse.svg'
import Photo from '../svg/support_photo.svg'
import Click from '../svg/support_click.svg'
import Frame from '../svg/support_frame.svg'
import Design from '../svg/support_design.svg'
import Consult from '../svg/support_consult.svg'

const ServiceItemIcon = (type) => {
    switch (type) {
        case 'analytics':
            return AnalyticsIcon;
        case 'audit':
            return AuditIcon;
        case 'monitor':
            return MonitorIcon;
        case 'search':
            return SearchIcon;
        case 'tech':
            return TechIcon;
        case 'text':
            return TextIcon;
        case 'context':
            return Context;
        case 'converse':
            return Converse;
        case 'automatic':
            return Automatic;
        case 'kpi':
            return Kpi;
        case 'mouse':
            return Mouse;
        case 'photo':
            return Photo;
        case 'click':
            return Click;
        case 'frame':
            return Frame;
        case 'design':
            return Design;
        case 'consult':
            return Consult;
        default:
            return '';
    }
}

const ServiceItem = (params) => {
    const item = params.service;
    return (
        <div className="service__item initial">
            <div className="service__item-icon">
                <img src={ServiceItemIcon(item.img)} alt={item.title} />
            </div>
            <div className="service__item-title">{item.title}</div>
            <div className="service__item-description text">{item.description}</div>
        </div>
    );
}

export default ServiceItem;