import React from 'react';
import InputMask from 'react-input-mask';

class PageForm extends React.Component {
    componentDidMount() {
        const elements = document.getElementsByClassName("page__form-input");
        for (let i = 0; i < elements.length; i++) {
            elements[i].addEventListener('focus', () => {
                elements[i].classList.remove('error-input');
            })
        }
    }

    sendFormData = async () => {
        let formResult = document.getElementsByClassName('page__form-result')[0];
        formResult.classList.remove('show');

        let sendObject = {};
        let sendAccess = true;

        if (document.getElementById('page-form-name').value.length > 0) {
            sendObject.name = document.getElementById('page-form-name').value;
        } else {
            document.getElementById('page-form-name').classList.add('error-input');
            sendAccess = false;
        }

        if (document.getElementById('page-form-phone').value.length > 0) {
            if (document.getElementById('page-form-phone').value.indexOf('_') > -1) {
                document.getElementById('page-form-phone').classList.add('error-input');
                sendAccess = false;
            } else {
                sendObject.phone = document.getElementById('page-form-phone').value;
            }
        } else {
            document.getElementById('page-form-phone').classList.add('error-input');
            sendAccess = false;
        }

        let id = '';
        if (getCookie('uid')) {
            id = getCookie('uid');
        } else {
            let number = Math.random() // 0.9394456857981651
            number.toString(36); // '0.xtis06h6'
            let id = number.toString(36).substr(2, 9); // 'xtis0
            document.cookie = 'uid=' + id;
        }

        sendObject.id = id;
        sendObject.method = 'page_request';
        sendObject.service = '';

        if (sendAccess) {
            let xhr = new XMLHttpRequest();
            xhr.open("POST", '/ajax.php', true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let data = JSON.parse(xhr.responseText);
                        if (data.success) {
                            const inputs = document.getElementsByClassName("page__form-input");
                            for (let i = 0; i < inputs.length; i++) {
                                inputs[i].value = '';
                            }
                            document.getElementsByClassName('forms-result__shadow')[0].classList.add('showed');
                        } else {
                            formResult.textContent = 'Произошла ошибка, попробуйте позже';
                            formResult.classList.add('show');
                        }
                    } else {
                        formResult.textContent = 'Произошла ошибка, попробуйте позже';
                        formResult.classList.add('show');
                    }
                }
            };
            xhr.send('param=' + JSON.stringify(sendObject));
        }
    }

    render() {
        return (
            <div className="page__form-section">
                <div className="page__form-suptitle text">Акция</div>
                <div className="page__form-title h2">Первый месяц работы - бесплатно!</div>
                <div className="page__form-description text">При заключении договора на продвижение вы можете бесплатно оценить нашу работу в течение 30 дней.</div>
                <div className="page__form-inputs">
                    <div className="page__form-input__wrapper">
                        <input type="text" id="page-form-name" className="page__form-input" placeholder="Имя и Фамилия" />
                    </div>
                    <div className="page__form-input__wrapper">
                        <InputMask className="page__form-input" id="page-form-phone" placeholder="Телефон" mask="+7 999 999 99 99" />
                    </div>
                    <input type="button" className="page__form-submit" id="page-form-apply" value="Попробовать" onClick={this.sendFormData.bind(this)}/>
                </div>
                <div className="page__form-result">Произошла ошибка, попробуйте позже</div>
                <div className="page__form-terms">
                    Нажимая на кнопку, вы даете <a href="/personal.pdf" target="_blank" rel="noreferrer">согласие на обработку персональных данных</a> и соглашаетесь с политикой конфиденциальности.
                </div>
            </div>
        )
    }
}

const getCookie = name => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export default PageForm;