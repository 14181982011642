import React from 'react';
import { NavLink } from 'react-router-dom';
import MenuIcon from "../svg/menu.svg";

export const animate = (elem, style, unit, from, to, time, prop) => {
    if (!elem) {
        return;
    }
    let start = new Date().getTime(), timer = setInterval(function () {
        let step = Math.min(1, (new Date().getTime() - start) / time);
        if (prop) {
            elem[style] = (from + step * (to - from))+unit;
        } else {
            elem.style[style] = (from + step * (to - from))+unit;
        }
        if (step === 1) {
            clearInterval(timer);
        }
    }, 25);

    if (prop) {
        elem[style] = from+unit;
    } else {
        elem.style[style] = from+unit;
    }
}

class Header extends React.Component {
    state = {
        lastScrollTop: 0,
    }

    onScroll = (e) => {
        if (document.querySelector('.header__menu-mobile').classList.contains('showed')) {
            document.querySelector('.header__menu-mobile').classList.remove('showed');
        }
        if (window.innerWidth < 1040) {
            let headerBox = document.getElementsByClassName('header__box')[0];
            if (e.target.documentElement.scrollTop > 400 && !headerBox.classList.contains('fixed')) {
                headerBox.classList.add('fixed');
                headerBox.style.top = '-90px';
            } else if (e.target.documentElement.scrollTop <= 400 && headerBox.classList.contains('fixed')) {
                headerBox.classList.remove('fixed');
                headerBox.style.top = '0px';
            }

            if (headerBox.classList.contains('fixed')) {
                if (e.target.documentElement.scrollTop < this.state.lastScrollTop) {
                    if (parseInt(headerBox.style.top) * -1 < 90) {
                        let nowTop = parseInt(headerBox.style.top);
                        nowTop -= (this.state.lastScrollTop - e.target.documentElement.scrollTop);
                        if (nowTop < -90) nowTop = -90;
                        headerBox.style.top = nowTop + 'px';
                    }
                } else {
                    if (parseInt(headerBox.style.top) * -1 > 0) {
                        let nowTop = parseInt(headerBox.style.top);
                        nowTop += (e.target.documentElement.scrollTop - this.state.lastScrollTop);
                        if (nowTop > 0) nowTop = 0;
                        headerBox.style.top = nowTop + 'px';
                    }
                }
            }

            this.setState({
                lastScrollTop: e.target.documentElement.scrollTop
            })
        } else {
            let headerBox = document.getElementsByClassName('header__box')[0];
            if (e.target.documentElement.scrollTop > 600 && !headerBox.classList.contains('fixed')) {
                headerBox.classList.add('fixed');
                headerBox.style.top = '-90px';
            } else if (e.target.documentElement.scrollTop <= 600 && headerBox.classList.contains('fixed')) {
                headerBox.classList.remove('fixed');
                headerBox.style.top = '30px';
            }

            if (headerBox.classList.contains('fixed')) {
                if (e.target.documentElement.scrollTop < this.state.lastScrollTop) {
                    if (parseInt(headerBox.style.top) * -1 < 90) {
                        let nowTop = parseInt(headerBox.style.top);
                        nowTop -= (this.state.lastScrollTop - e.target.documentElement.scrollTop);
                        if (nowTop < -90) nowTop = -90;
                        headerBox.style.top = nowTop + 'px';
                    }
                } else {
                    if (parseInt(headerBox.style.top) * -1 > 0) {
                        let nowTop = parseInt(headerBox.style.top);
                        nowTop += (e.target.documentElement.scrollTop - this.state.lastScrollTop);
                        if (nowTop > 0) nowTop = 0;
                        headerBox.style.top = nowTop + 'px';
                    }
                }
            }

            this.setState({
                lastScrollTop: e.target.documentElement.scrollTop
            })
        }
    }


    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
        document.querySelector('.header__menu-open').addEventListener('click', (e) => {
           document.querySelector('.header__menu-mobile').classList.toggle('showed');
           if (window.innerWidth < 767) {
               document.getElementsByTagName('body')[0].classList.add('no-scroll');
           }
        });

        const mobileLinks = document.getElementsByClassName('header__menu-mobile__link');
        for (let i = 0; i < mobileLinks.length; i++) {
            mobileLinks[i].addEventListener('click', (e) => {
                document.querySelector('.header__menu-mobile').classList.remove('showed');
                if (window.innerWidth < 767) {
                    document.getElementsByTagName('body')[0].classList.remove('no-scroll');
                }
            });
        }

        document.querySelector('.header__menu-mobile__close').addEventListener('click', (e) => {
            document.querySelector('.header__menu-mobile').classList.remove('showed');
            if (window.innerWidth < 767) {
                document.getElementsByTagName('body')[0].classList.remove('no-scroll');
            }
        });
    }

    render() {
        return (
            <div className="header__section">
                <div className="wrapper">
                    <div className="header__place"/>
                </div>
                <div className="header__box">
                    <div className="wrapper">
                        <div className="header">
                            <div className="header__menu-open">
                                <img src={MenuIcon} alt="" />
                            </div>
                            <NavLink exact to="/" className="header__logo" title="Главная">
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="50" height="50" rx="25" fill="currentColor"/>
                                    <path d="M26.5714 15H14V22.5251H17.3642C17.2314 23.1449 17.1871 23.7646 17.1871 24.4286C17.1429 25.4024 16.3018 26.1992 15.328 26.1992H14V33.7243H15.3722C20.5513 33.6801 24.7123 29.4748 24.7123 24.2958C24.7565 23.3219 25.5976 22.5251 26.5714 22.5251C27.5895 22.5251 28.4748 23.3662 28.4748 24.4286V33.8571H36V24.4286C36 19.2052 31.7948 15 26.5714 15Z" fill="white"/>
                                </svg>
                                <span>AutoTeam</span>
                            </NavLink>
                            <nav className="header__menu">
                                <NavLink exact to="/about"><span>О проекте</span></NavLink>
                                <NavLink to="/support"><span>Поддержка сайта</span></NavLink>
                                <NavLink to="/advertising"><span>Реклама</span></NavLink>
                                <NavLink to="/seo"><span>SEO-сопровождение</span></NavLink>
                                <a href="#contact"><span>Контакты</span></a>
                            </nav>
                        </div>
                        <div className={"header__menu-mobile"}>
                            <NavLink exact to="/about" className={"header__menu-mobile__link"}><span>О проекте</span></NavLink>
                            <NavLink to="/support" className={"header__menu-mobile__link"}><span>Поддержка сайта</span></NavLink>
                            <NavLink to="/advertising" className={"header__menu-mobile__link"}><span>Реклама</span></NavLink>
                            <NavLink to="/seo" className={"header__menu-mobile__link"}><span>SEO-сопровождение</span></NavLink>
                            <div className={"header__menu-mobile__contact"}>
                                <a href="tel:88002018780" className="header__menu-phone">8 800-201-87-80</a>
                                <a href="mailto:auto@plastilin-lab.ru" className="header__menu-email">auto@plastilin-lab.ru</a>
                            </div>
                            <button className="header__menu-mobile__close">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.8323 9.99994L19.6199 2.21211C20.1267 1.70554 20.1267 0.886491 19.6199 0.379925C19.1133 -0.126642 18.2943 -0.126642 17.7877 0.379925L9.99988 8.16776L2.21228 0.379925C1.70548 -0.126642 0.886669 -0.126642 0.380103 0.379925C-0.126701 0.886491 -0.126701 1.70554 0.380103 2.21211L8.1677 9.99994L0.380103 17.7878C-0.126701 18.2943 -0.126701 19.1134 0.380103 19.62C0.632555 19.8726 0.964493 19.9996 1.29619 19.9996C1.62789 19.9996 1.95959 19.8726 2.21228 19.62L9.99988 11.8321L17.7877 19.62C18.0404 19.8726 18.3721 19.9996 18.7038 19.9996C19.0355 19.9996 19.3672 19.8726 19.6199 19.62C20.1267 19.1134 20.1267 18.2943 19.6199 17.7878L11.8323 9.99994Z"
                                        fill="black"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;