import React from 'react';

const ReviewItem = (params) => {
    const item = params.review;
    return (
        <div className="review__item">
            <div className="review__item-contact">
                <div className="review__item-icon">
                    <img src={item.logo} alt={""} />
                </div>
                <div className="review__item-name text">{item.name}</div>
                <div className="review__item-position">{item.position}</div>
            </div>
            <div className="review__item-content">
                {item.title && <div className="review__item-title">{item.title}</div>}
                <div className="review__item-description text" dangerouslySetInnerHTML={ {__html: item.description} }/>
            </div>
        </div>
    );
}

export default ReviewItem;