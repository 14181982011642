import React from 'react';
import {Helmet} from "react-helmet";
import ServiceItem from "../components/service_item";
import PageForm from "../components/page_form";
import ServiceVideo from "../components/service_video";
import {animate} from "../components/Header";

const serviceItems = [
    {
        'img': 'context',
        'title': 'Настройка контекстной рекламы',
        'description': 'Собираем ключевые фразы, создаем и настраиваем кабинеты, таргетируем аудитории.'
    },
    {
        'img': 'monitor',
        'title': 'Аудит текущей рекламной кампании',
        'description': 'Анализируем сайт, составляем отчет по трафику, анализируем CPC, CPA, CPO.'
    },
    {
        'img': 'converse',
        'title': 'Подключение конверсионых инструментов',
        'description': 'Подключаем сервисы статистики, настраиваем цели для отчета по конверсиям.'
    },
    {
        'img': 'analytics',
        'title': 'Настройка сквозной аналитики',
        'description': 'Помогаем поставить бизнес-процессы на рельсы электронной коммерции.'
    },
    {
        'img': 'automatic',
        'title': 'Внедрение сервисов автоматизации',
        'description': 'Используем в работе  инструменты автоматизации Roistat, eLama, K50.'
    },
    {
        'img': 'kpi',
        'title': 'Постановка и выполнение KPI в кампании',
        'description': 'Формируем индивидуальные KPI и  отчитываемся о проделанной работе.'
    },
]

class Advertising extends React.Component {
    state = {
        lastScrollTop: 0,
        animatedShowServices: false,
        animatedShowPrices: false,

    }

    isElementInViewport = (el) => {
        let rect = el.getBoundingClientRect();
        return (
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.bottom >= 0
        );
    }

    componentDidMount() {
        this.setState({
            lastScrollTop: document.documentElement.scrollTop
        });
        const supportPageOffset = window.pageYOffset !== undefined;
        const isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        const yPage = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

        if (document.documentElement.scrollTop > 0 && document.getElementsByClassName('service__pluses-items').length > 0) {
            let scrollFromTopToMiddle = document.documentElement.scrollTop + document.documentElement.clientHeight / 2;
            let rect = document.getElementsByClassName('service__pluses-items')[0].getBoundingClientRect();
            if (scrollFromTopToMiddle >= (rect.top + yPage)) {
                let completedPixels = scrollFromTopToMiddle - (rect.top + yPage);
                let currentPixels = document.getElementsByClassName('service__pluses-progress__step')[0].clientHeight;
                if (completedPixels >= document.getElementsByClassName('service__pluses-progress')[0].clientHeight) {
                    completedPixels = document.getElementsByClassName('service__pluses-progress')[0].clientHeight;
                }
                if (currentPixels < completedPixels && completedPixels <= document.getElementsByClassName('service__pluses-progress')[0].clientHeight) {
                    document.getElementsByClassName('service__pluses-progress__step')[0].style.height = completedPixels + 'px';
                }

                const plusItems = document.getElementsByClassName('service__pluses-item');
                for (let i = 0; i < plusItems.length; i++) {
                    if (!plusItems[i].classList.contains('showed')) {
                        let rectItem = plusItems[i].getBoundingClientRect();
                        if (scrollFromTopToMiddle >= (rectItem.top + rectItem.height / 2 + yPage)) plusItems[i].classList.add('showed');
                    }
                }
            }
        }
        document.getElementsByTagName('body')[0].classList.add('services');
        document.getElementsByTagName('body')[0].classList.remove('index');
        window.addEventListener('scroll', this.onScroll);
        if (
            this.isElementInViewport(document.querySelector('.service__top')) &&
            document.querySelector('.service__top-title').classList.contains('initial')
        ) {
            document.querySelector('.service__top-title').classList.remove('initial');
            document.querySelector('.service__top-description').classList.remove('initial');
            setTimeout(() => document.querySelector('.service__top-button').classList.remove('initial'), 600);
        }
    }

    onScroll = (e) => {
        let scrollFromTop = e.target.documentElement.scrollTop + e.target.documentElement.clientHeight;
        let scrollFromTopToMiddle = e.target.documentElement.scrollTop + e.target.documentElement.clientHeight / 2;

        const supportPageOffset = window.pageYOffset !== undefined;
        const isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        const yPage = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

        if (document.getElementsByClassName('service__pluses-items').length > 0) {
            let rect = document.getElementsByClassName('service__pluses-items')[0].getBoundingClientRect();
            if (scrollFromTopToMiddle >= (rect.top + yPage) && this.state.lastScrollTop < e.target.documentElement.scrollTop) {
                let completedPixels = scrollFromTopToMiddle - (rect.top + yPage);
                let currentPixels = document.getElementsByClassName('service__pluses-progress__step')[0].clientHeight;
                if (currentPixels < completedPixels && completedPixels <= document.getElementsByClassName('service__pluses-progress')[0].clientHeight) {
                    document.getElementsByClassName('service__pluses-progress__step')[0].style.height = completedPixels + 'px';
                }

                const plusItems = document.getElementsByClassName('service__pluses-item');
                for (let i = 0; i < plusItems.length; i++) {
                    if (!plusItems[i].classList.contains('showed')) {
                        let rectItem = plusItems[i].getBoundingClientRect();
                        if (scrollFromTopToMiddle >= (rectItem.top + rectItem.height / 2 + yPage)) plusItems[i].classList.add('showed');
                    }
                }
            }
        }
        if (document.getElementsByClassName('service__info-items').length > 0) {
            let rectServices = document.getElementsByClassName('service__info-items')[0].getBoundingClientRect();
            if (scrollFromTop - 250 >= (rectServices.top + yPage) && !this.state.animatedShowServices) {
                this.setState({
                    animatedShowServices: true
                });
                this.animateShowServices();
            }
        }
        if (document.getElementsByClassName('service__prices-list').length > 0) {
            let rectPrices = document.getElementsByClassName('service__prices-list')[0].getBoundingClientRect();
            if (scrollFromTop - 250 >= (rectPrices.top + yPage) && !this.state.animatedShowPrices) {
                this.setState({
                    animatedShowPrices: true
                });
                this.animateShowPrices();
            }
        }
        this.setState({
            lastScrollTop: e.target.documentElement.scrollTop
        })

        if (document.querySelector('.service__top') !== null) {
            if (
                this.isElementInViewport(document.querySelector('.service__top')) &&
                document.querySelector('.service__top-title').classList.contains('initial')
            ) {
                document.querySelector('.service__top-title').classList.remove('initial');
                document.querySelector('.service__top-description').classList.remove('initial');
                setTimeout(() => document.querySelector('.service__top-button').classList.remove('initial'), 600);
            }
        }
    }

    animateShowServices = () => {
        const serviceItems = document.getElementsByClassName('service__item');
        for (let i = 0; i < serviceItems.length; i++) {
            setTimeout(function () {
                serviceItems[i].classList.remove('initial')
            }, 200 * i);
        }
    }

    animateShowPrices = () => {
        const servicePrices = document.getElementsByClassName('service__price');
        for (let i = 0; i < servicePrices.length; i++) {
            setTimeout(function () {
                servicePrices[i].classList.remove('initial')
            }, 200 * i);
        }
    }

    scrollToServices = () => {
        let target = document.getElementsByClassName("service__info-title")[0];
        animate(document.scrollingElement || document.documentElement, "scrollTop", "", document.documentElement.scrollTop, target.offsetTop - 50, 500, true);
    }

    scrollToForm = (e) => {
        document.getElementById('page-footer-service').value = e.currentTarget.getAttribute('data-service');
        document.getElementById('page-footer-tariff').value = e.currentTarget.getAttribute('data-tariff');
        let target = document.getElementsByClassName("footer-form")[0];
        animate(document.scrollingElement || document.documentElement, "scrollTop", "", document.documentElement.scrollTop, target.offsetTop - 50, 500, true);
    }

    render() {
        return (
            <section className="service__page advertising-page">
                <Helmet>
                    <title>Настройка контекстной рекламы для автомобильных сайтов | Интернет-лаборатория Пластилин AutoTeam</title>
                    <meta name="description" content="Закажите качественную настройку контекстной рекламы для автомобильного сайта ✔ Анализ спроса ✔ Настройка рекламной компании ✔ Контроль и аналитика расходов ★ Интернет-лаборатория Пластилин AutoTeam" />
                </Helmet>
                <div className="wrapper">
                    <div className="service__top">
                        <div className="service__top-content">
                            <h1 className="service__top-title h2 initial">Реклама</h1>
                            <div className="service__top-description text initial">Настраиваем рекламу для автодилеров. Помогаем найти новых клиентов из поисковых систем.</div>
                            <button className="service__top-button arrow-button initial" onClick={this.scrollToServices}><span>Подробнее</span></button>
                        </div>
                        <div className="service__top-banner">
                            <ServiceVideo type="advertising"/>
                        </div>
                    </div>
                    <div className="service__info">
                        <h2 className="service__info-title h2">Услуга включает</h2>
                        <div className="service__info-description text">
                            Настройку и сопровождение контекстной рекламы, подключение инструментов сквозной аналитики.
                        </div>
                        <div className="service__info-items">
                            {
                                serviceItems.map((service, i) => {
                                    return (<ServiceItem service={service} key={`service_` + i}/>);
                                })
                            }
                        </div>
                    </div>
                    <div className="service__pluses">
                        <h2 className="service__pluses-title h2">Преимущества работы с нами</h2>
                        <div className="service__pluses-list">
                            <div className="service__pluses-items">
                                <div className="service__pluses-item">
                                    <span>Сертифицированное агентство Яндекс.Директ / Google Ads</span>
                                    <div className="service__pluses-item__dot"/>
                                </div>
                                <div className="service__pluses-item">
                                    <div className="service__pluses-item__dot"/>
                                    <span>Готовые наборы семантики для ДЦ: бренд, модели, сервис</span>
                                </div>
                                <div className="service__pluses-item">
                                    <span>Выделенный менеджер в работе с проектом</span>
                                    <div className="service__pluses-item__dot"/>
                                </div>
                                <div className="service__pluses-item">
                                    <div className="service__pluses-item__dot"/>
                                    <span>Доработка сайтов под цели рекламы</span>
                                </div>
                                <div className="service__pluses-item">
                                    <span>Составление отчетов для импортера, работа с ЭДО</span>
                                    <div className="service__pluses-item__dot"/>
                                </div>
                            </div>
                            <div className="service__pluses-progress"><span className="service__pluses-progress__step"/></div>
                        </div>
                    </div>
                </div>
                <div className="service__form">
                    <PageForm />
                </div>
                <div className="service__prices">
                    <h2 className="service__prices-title h2">Стоимость услуг</h2>
                    <div className="service__prices-text text">
                        Мы предлагаем три тарифа для удобства работы с нами в зависимости от типа и сложность задач.
                    </div>
                    <div className="service__prices-list">
                        <div className="service__price initial">
                            <div className="service__price-hour"><span className="service__price-hour__time">15</span>час/мес*</div>
                            <div className="service__price-name">Точный</div>
                            <div className="service__price-description text">
                                Тариф подойдет для региональных ДЦ и монобрендовых шоурумов
                            </div>
                            <ul className="service__price-items">
                                <li>Быстрый старт</li>
                                <li>Настройка аналитики</li>
                                <li>Ежемесячный отчет</li>
                            </ul>
                            <button className="service__price-button" onClick={this.scrollToForm} data-tariff="Легкий" data-service="Реклама">Начать работу</button>
                        </div>
                        <div className="service__price initial">
                            <div className="service__price-hour">
                                <span className="service__price-hour__prefix">от</span>
                                <span className="service__price-hour__time">25</span>час/мес*
                                </div>
                            <div className="service__price-name">Ключевой</div>
                            <div className="service__price-description text">
                                Тариф подойдет для мультибрендовых ДЦ или дилерской сети в рамках одного региона
                            </div>
                            <ul className="service__price-items">
                                <li>Запуск от 2 недель</li>
                                <li>Настройка аналитики</li>
                                <li>Работа с KPI</li>
                                <li>Рекомендации по сайту</li>
                                <li>Ежемесячный отчет</li>
                            </ul>
                            <button className="service__price-button" onClick={this.scrollToForm} data-tariff="Стандартный" data-service="Реклама">Начать работу</button>
                        </div>
                        <div className="service__price initial">
                            <div className="service__price-hour">
                                <span className="service__price-hour__prefix">от</span>
                                <span className="service__price-hour__time">45</span>час/мес*
                            </div>
                            <div className="service__price-name">Расширенный</div>
                            <div className="service__price-description text">
                                Тариф подойдет для крупных дилеров и импортеров, работающих в нескольких регионах
                            </div>
                            <ul className="service__price-items">
                                <li>Запуск от 2 недель</li>
                                <li>Настройка аналитики</li>
                                <li>Работа с KPI</li>
                                <li>Рекомендации по сайту</li>
                                <li>Ежемесячный отчет</li>
                            </ul>
                            <button className="service__price-button" onClick={this.scrollToForm} data-tariff="Продвинутый" data-service="Реклама">Начать работу</button>
                        </div>
                    </div>
                    <div className="service__prices-description">*Стоимость часа работ рассчитывается исходя из ставки специалиста 1 000 рублей в час</div>
                </div>
            </section>
        );
    }
}

export default Advertising;